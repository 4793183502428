<template>
    <div>
        <CompTable ref="comp_table" title="评价管理" isSelector :columns="columns" :table-api="tableApi" :dataBefore="onSearchDataBefore" :searchData="searchData">
            <template #search="evt">
                <Input v-model="evt.search.title" placeholder="商品名称" v-if="type === 'shop'"></Input>
                <Input v-model="evt.search.goodsNum" placeholder="货号" v-if="type === 'shop'"></Input>
                <Select v-model="evt.search.status" placeholder="选择状态">
                    <Option value="">全部</Option>
                    <Option value="0">待审核</Option>
                    <Option value="1">屏蔽</Option>
                    <Option value="2">公开</Option>
                </Select>
                <DatePicker v-model="evt.search.startDate" type="date" placement="bottom-end" placeholder="开始日期" />
                <DatePicker v-model="evt.search.endDate" type="date" placement="bottom-end" placeholder="结束日期" />
            </template>
            <template #operate>
                <Button type="primary" @click="onSubmitPass">一键公开</Button>
            </template>
        </CompTable>

        <Preview ref="preview"></Preview>
    </div>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import Preview from "./components/Preview.vue"
import Request from "../jointly/utils/request"

export default {
    components: {
        CompTable,
        Preview,
    },

    data() {
        const type = this.$core.getUrlParam("t") || "jointly"

        return {
            type: type,
            tableApi: `/gateway/api/sycomponent/pc/comments/listMerchantCommentsByPage?evaluationType=${["jointly", "shop"].indexOf(type)}`,
            columns: [
                {
                    title: "评价内容",
                    minWidth: 500,
                    align: "center",
                    render: (h, params) => {
                        const files = []

                        const images = params.row.images?.split(",")

                        images?.forEach(item => {
                            if (!item) return
                            files.push(
                                h("div", {
                                    style: {
                                        width: "50px",
                                        height: "50px",
                                        margin: "3px",
                                        background: `url(${item})`,
                                        "background-size": "cover",
                                        "background-position": "center",
                                        "border-radius": "5px",
                                        cursor: "pointer",
                                    },
                                    on: {
                                        click: () => {
                                            this.$refs.preview.display(item, images)
                                        },
                                    },
                                })
                            )
                        })

                        if (params.row.videos) {
                            files.push(
                                h("video", {
                                    attrs: {
                                        src: params.row.videos,
                                        controls: true,
                                    },
                                    style: {
                                        width: "150px",
                                        height: "100px",
                                        margin: "3px",
                                        "border-radius": "5px",
                                    },
                                })
                            )
                        }

                        return h(
                            "div",
                            {
                                style: {
                                    margin: "10px 0",
                                },
                            },
                            [
                                h(
                                    "p",
                                    {
                                        style: {
                                            "text-align": "center",
                                        },
                                    },
                                    params.row.content || "-"
                                ),
                                ...(files.length > 0
                                    ? [
                                          h(
                                              "div",
                                              {
                                                  style: {
                                                      display: "flex",
                                                      "justify-content": "center",
                                                      "flex-wrap": "wrap",
                                                      "margin-top": "5px",
                                                  },
                                              },
                                              files
                                          ),
                                      ]
                                    : []),
                            ]
                        )
                    },
                },
                {
                    title: "评分",
                    minWidth: 100,
                    key: "score",
                    align: "center",
                },
                {
                    title: "评价时间",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy/MM/dd hh:mm:ss") : "-")
                    },
                },
                {
                    title: "评价账号",
                    minWidth: 200,
                    align: "center",
                    key: "mobile",
                },
                ...(type === "shop"
                    ? [
                          {
                              title: "商品名称",
                              minWidth: 200,
                              key: "goodsName",
                              align: "center",
                              render: (h, params) => {
                                  return h("p", JSON.parse(params.row.extra || "{}").goodsName || "-")
                              },
                          },
                      ]
                    : []),
                ...(type === "shop"
                    ? [
                          {
                              title: "货号",
                              minWidth: 200,
                              key: "goodsNum",
                              align: "center",
                          },
                      ]
                    : []),
                {
                    title: "商家名称",
                    minWidth: 200,
                    key: "title",
                    align: "center",
                },
                {
                    title: "审核状态",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        let status = parseInt(params.row.status || "0")

                        return h(
                            "p",
                            {
                                style: {
                                    color: ["#1f90ff", "red", "green"][status] || "",
                                },
                            },
                            ["待审核", "屏蔽", "公开"][status] || "-"
                        )
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: "right",
                    minWidth: 150,
                    render: (h, params) => {
                        const buttons = []

                        if (["0", "1"].includes(params.row.status)) {
                            buttons.push(
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "info",
                                            size: "small",
                                        },
                                        style: "margin:0 3px",
                                        nativeOn: {
                                            click: () => {
                                                this.onChangeStatus(params.row.infoId, 2)
                                            },
                                        },
                                    },
                                    "公开"
                                )
                            )
                        }

                        if (["0", "2"].includes(params.row.status)) {
                            buttons.push(
                                h(
                                    "Button",
                                    {
                                        props: {
                                            type: "error",
                                            size: "small",
                                        },
                                        style: "margin:0 3px",
                                        nativeOn: {
                                            click: () => {
                                                this.onChangeStatus(params.row.infoId, 1)
                                            },
                                        },
                                    },
                                    "屏蔽"
                                )
                            )
                        }

                        return buttons
                    },
                },
            ],
            // 搜索数据
            searchData: {
                status: "0",
            },
            // 时间范围
            search_date: [],
        }
    },

    methods: {
        /**
         * 处理搜索数据
         */
        onSearchDataBefore(data) {
            const res = JSON.parse(JSON.stringify(data))

            if (res.startDate) {
                res.startDate = this.$core.formatDate(new Date(res.startDate), "yyyy-MM-dd")
            }

            if (res.endDate) {
                res.endDate = this.$core.formatDate(new Date(res.endDate), "yyyy-MM-dd")
            }

            return res
        },

        /**
         * 改变状态
         */
        onChangeStatus(id, status) {
            Request.post(`/gateway/api/sycomponent/pc/comments/commentModeration`, {
                infoId: id instanceof Array ? id.join(",") : id,
                status,
            }).then(() => {
                this.$Message.success("提交成功")
                this.$refs.comp_table.refresh()
            })
        },

        /**
         * 一键公开
         */
        onSubmitPass() {
            let selection = this.$refs.comp_table.getSelection()
            if (!selection || selection.length == 0) {
                return this.$Message.warning("请选择需要公开的评价")
            }
            // 排除已公开的
            let ids = selection.filter(item => item.status != 2).map(item => item.infoId)
            // 如果无需公开
            if (ids.length == 0) {
                this.$Message.warning("已公开的评价无需再次公开")
                // 刷新
                this.$refs.comp_table.refresh()
                // 结束
                return
            }
            // 提交
            this.onChangeStatus(ids, 2)
        },
    },
}
</script>

<style></style>
